
import { FC, useEffect } from 'react'

import AOS from 'aos'
import 'aos/dist/aos.css'

import styles from './styles.module.scss'

import variants from './variants.json'

const WhyAreWe: FC<any> = () => {

  useEffect(() => {
    AOS.init()
  }, [])
  
  return (
    <div className={styles.container}>
      <div id='Suitable' className={styles.content}>

      <h1 data-aos="zoom-in">Основные характеристики</h1>

        <div data-aos="zoom-down" className={styles.description}>
        	В комплекте - усилитель, вибродинамик, блок питания, кабель 3.5 jack, 
        </div>

        <div className={styles.section}>

          {variants?.map((item:any, index:number) => (
            <div data-aos="zoom-in" data-aos-duration={500*index} key={item.title} className={styles.item}>

              <div className={styles.first}/>
              
              <div className={styles.three}/>

              <div className={styles.title}>
                {item.title}
                
                <div className={styles.description}>
                
                  {item.description}
                </div>
              </div>
              
            </div>
          ))}
          
        </div>
        </div>
    </div>
  )
}

export default WhyAreWe
