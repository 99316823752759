
const msToHMS = (duration: number): string => {
  //let milliseconds:number|string = Math.abs(parseInt(((duration / 1000)).toString()))
  let seconds:number|string = Math.abs(parseInt(((duration / 1000) % 60).toString()))
  let minutes:number|string = Math.abs(parseInt(((duration / (1000 * 60)) % 60).toString()))
  let hours:number|string = Math.abs(parseInt(((duration / (1000 * 60 * 60)) % 24).toString()))

  hours = (hours < 10) ? "0" + hours : hours
  minutes = (minutes < 10) ? "0" + minutes : minutes
  seconds = (seconds < 10) ? "0" + seconds : seconds

  return `${hours}:${minutes}:${seconds}`
}

export default msToHMS
