
import { FC, useEffect } from 'react'

import AOS from 'aos'
import 'aos/dist/aos.css'

import scrollToElement from 'scroll-to-element';

import styles from './styles.module.scss'

const Footer: FC<any> = () => {

  useEffect(() => {
    AOS.init({
      offset: 50,
    })
  }, [])
  
  const scrollTo = (to:string, offset: number):void => {
    
    scrollToElement(to, {
        offset: offset,
        duration: 1500
    })
  }

  return (
    <div className={styles.container}>
    
      <div className={styles.content}>
      
        <div id='Footer' className={styles.section}>

          <div className={styles.first}/>
          <div className={styles.two}/>
          <div className={styles.three}/>
          <div className={styles.four}/>
          <div className={styles.five}/>
          <div className={styles.six}/>
          <div className={styles.seven}/>

          <div className={styles.content}>

            <div data-aos="zoom-in" data-aos-duration="500" className={styles.logo}>
              антисосед.рф
            </div>

            <div data-aos="zoom-in" data-aos-duration="1000"  className={styles.oth}>

              <div className={styles.phone}>
                <a href='tel:+7 950 291 33 51'>+7 950 291 33 51</a>
                /
                <a href='email:info@антисосед.рф'>info@антисосед.рф</a>
              </div>

              <div className={styles.address}>
              г. Владивосток, ул. Сельская, 6
              </div>

            </div>

            <div data-aos="zoom-in" data-aos-anchor-placement="top-bottom" data-aos-duration="1500" className={styles.menu}>

              <div onClick={()=>{scrollTo('#PhotoGallery', 0)}}>
                   Комплект
              </div>
              
              <div onClick={()=>{scrollTo('#Suitable', 0)}}>
                   Характеристики
              </div>
              
              <div onClick={()=>{scrollTo('#WhydoYouNeed', -50)}}>
                    Отзывы
              </div>
              
              <div onClick={()=>{scrollTo('#WhyAreWe', 0)}}>
                    Доставка и оплата
              </div>
              
              <div onClick={()=>{scrollTo('#Faq', -50)}}>
                    Вопросы - ответы
              </div>     
                       
              <div onClick={()=>{scrollTo('#Footer', 0)}}>
                    Контакты
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>
  )
}

export default Footer
