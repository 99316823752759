
import { FC, useEffect, useState } from 'react'

import styles from './styles.module.scss'

import * as React from 'react'

import AOS from 'aos'
import 'aos/dist/aos.css'

import FsLightbox from 'fslightbox-react'


const mini = [
  '/images/PhotoGallery/vibro/1692756943292mini.jpg',
    '/images/PhotoGallery/vibro/1692756943359mini.jpg',
      '/images/PhotoGallery/vibro/1692756943425mini.jpg',
        '/images/PhotoGallery/vibro/1692756943504mini.jpg',
          '/images/PhotoGallery/vibro/1692756943590mini.jpg',
            '/images/PhotoGallery/vibro/1692756943665mini.jpg',
]

const full = [
  '/images/PhotoGallery/vibro/1692756943292.jpg',
    '/images/PhotoGallery/vibro/1692756943359.jpg',
      '/images/PhotoGallery/vibro/1692756943425.jpg',
        '/images/PhotoGallery/vibro/1692756943504.jpg',
          '/images/PhotoGallery/vibro/1692756943590.jpg',
            '/images/PhotoGallery/vibro/1692756943665.jpg',
]

const CountStatisticUp: FC = () => {

  useEffect(() => {
    AOS.init()
  }, [])

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1
  })

  function openLightboxOnSlide(number: any) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number
    })
  }

  return ( 
    <div className={styles.container}>
      <div className={styles.content}>
        <div id='PhotoGallery' className={styles.section} style={{ margin: 0, marginTop: 50, marginBottom: 50 }}>
          <h1 data-aos="zoom-in">
            Фотографии
          </h1>
          <div className={styles.content}>
            <FsLightbox
              toggler={lightboxController.toggler}
              sources={full}
              slide={lightboxController.slide}
            />
            <div className={styles.flex}>
              {mini.map((item, i) => (
                <div key={i} data-aos="fade-up" data-aos-anchor-placement="top-bottom" className={styles.item} onClick={() => { openLightboxOnSlide(i + 1) }}>

                  <img  className={styles.hover}
                  src={item}
                  alt=""
                  title=""
                  width="380"
                  height="200"
                  style={{maxWidth: `calc(100vw - 60px)`}}
                  />

                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CountStatisticUp
