
import React, { FC, useState, useEffect } from 'react'

import {motion} from 'framer-motion'

const Training: FC = () => {

  const [activeTraining, setActiveTraining] = useState(true)

  const height = 70
  const width = 20

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  })

  const onScroll = (e: Event) => {
    const window = e.currentTarget as Window
    let currentPosition = window.scrollY

    if(currentPosition > 50){
      setActiveTraining(false)
    }else{
      setActiveTraining(true)
    }
  }

  return  <>
            { activeTraining &&
            <>
              <motion.div
                style={{
                  position: 'fixed',
                  height: height,
                  width: width,
                  backgroundColor: 'rgba(255, 255, 255, 0.85)',
                  padding: 10,
                  borderRadius: 20,
                  zIndex: 13,
                  fontSize: 16,
                }}
                initial={{ x: "50%" }}
                animate={{ x: "calc(100vw - 50%)",
                backgroundColor: "#000",
                boxShadow: "10px 10px 0 rgba(0, 0, 0, 0.2)",
                position: "fixed",
                transitionEnd: {
                  display: "none",
                },
              }}
              />
              <motion.div
              style={{
                position: 'fixed',
                left: 'calc(50vw - 10px)',
                top:  'calc(100vh - 100px)',
                height: height,
                width: width,
                color: 'rgb(44, 44, 44)',
                backgroundColor: 'rgba(255, 255, 255, 0.85)',
                padding: 10,
                borderRadius: 20,
                textAlign: 'center',
                zIndex: 13,
                fontSize: 16,
              }}
              animate={{
                opacity: 0,
                scale: 1.1,
                x: 0,
                y: -100,
                transition: {
                  type: 'spring',
                  duration: 1.1,
                  repeat: Infinity
                },
              }}/>
              </>
            }
          </>
}

export default Training