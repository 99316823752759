import { FC, useState } from 'react'

import scrollToElement from 'scroll-to-element'

import styles from './styles.module.scss'

import Hamburger from 'hamburger-react'

import useMediaQuery from '@mui/material/useMediaQuery'

import MenuMobile from '@components/MenuMobile'

const Header: FC<any> = () => {

  const matchesTable = useMediaQuery('(min-width:1200px)')
  const matchesMobile = useMediaQuery('(min-width:800px)')
  
  const [menu, setMenu] = useState<boolean>(false) 
  
  const scrollTo = (to:string, offset: number):void => {
    
    scrollToElement(to, {
        offset: offset,
        duration: 1800
    })
  }
  
  return (
    <div className={styles.container}>
      <div className={styles.content}>

        <div className={`${styles.item} ${styles.logo}`}>
                <div onClick={()=>{scrollTo('#Intro', 0)}}>
                  Антисосед.рф
                </div>
              </div>
        { matchesTable && matchesMobile &&
          <>
            <div className={`${styles.item} ${styles.menu}`}>

              <div onClick={()=>{scrollTo('#PhotoGallery', 0)}}>
                   Комплект
              </div>
              
              <div onClick={()=>{scrollTo('#Suitable', 0)}}>
                   Характеристики
              </div>
              
              <div onClick={()=>{scrollTo('#WhydoYouNeed', -50)}}>
                    Отзывы
              </div>
              
              <div onClick={()=>{scrollTo('#WhyAreWe', 0)}}>
                    Доставка и оплата
              </div>
              
              <div onClick={()=>{scrollTo('#Faq', -50)}}>
                    Вопросы - ответы
              </div>     
                       
              <div onClick={()=>{scrollTo('#Footer', 0)}}>
                    Контакты
              </div>

            </div>
            <div className={`${styles.item} ${styles.call}`}>
              <div>
                  <a href='tel:+7 950 29 133 51'>
                  +7 (950) 291 33 51
                  </a>
              </div>
            </div>
          </>
        }
        <MenuMobile open={menu} setOpen={setMenu}/>
        { !matchesTable &&
          <div className={`${styles.item} ${styles.Hamburger}`}>
            <Hamburger direction="right" toggled={menu} toggle={setMenu}  />
          </div>
        }
      </div>
    </div>
  )
}

export default Header
