

import { FC, useState, useEffect } from 'react'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import AnimateHeight from 'react-animate-height'

import AOS from 'aos'
import 'aos/dist/aos.css'

import styles from './styles.module.scss'

import variants from './variants.json'

const Faq: FC<any> = () => {

  useEffect(() => {
    AOS.init()
  }, [])

  const [open, setOpen] = useState<number>(-1)

  const onClick = (id: number) => {
    if(open===id){
      return setOpen(-1)
    }
    setOpen(id)
  }

  return (
      <div className={styles.container}>
        <div id="Faq" className={styles.content}>
          <div className={styles.section}>
            <h1  data-aos="zoom-in">
              Вопросы и ответы
            </h1>
            <div className={styles.section}>
              <div className={styles.tabs}>
                <div className={styles.tabsBox}>
                  {variants?.map((item:any) => (
                    <div data-aos="fade-right" key={item.id} onClick={() => onClick(item.id)} className={open === item.id ? styles.tabsBoxItem:styles.tabsBoxItem}>
                      <div className={styles.tabsBoxItemImage}>
                      
                        <AddCircleOutlineIcon fontSize="large" style={{transform: open === item.id ? 'rotate(45deg)':'unset', transition: '550ms'}}/>
                        
                      </div>
                      <div className={styles.tabsBoxItemData}>
                        <div className={styles.tabsBoxItemTitle}>
                          {item.title}
                          </div>
                        <AnimateHeight
                          duration={500}
                          height={open === item.id ? 'auto':0}
                        >
                          <div className={styles.tabsBoxItemDescription}>
                            {item.description}
                          </div>
                        </AnimateHeight>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div data-aos="fade-up" data-aos-duration="2000" className={styles.image} style={{display: 'flex', height: 720}}>
                <img
                src=''
                alt=''
                title=''
                />
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Faq

