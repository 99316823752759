
import { FC, useEffect, useState } from 'react'

import axios from 'axios'

import ym from 'react-yandex-metrika';

import AOS from 'aos'
import 'aos/dist/aos.css'

import MuiPhoneNumber from 'material-ui-phone-number'

import { useForm, Controller } from 'react-hook-form'

import * as React from 'react'
import Box from '@mui/material/Box'
import { LoadingButton } from '@mui/lab'

import TextField from '@mui/material/TextField'

import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'

import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"

import { styled } from "@mui/system";

import styles from './styles.module.scss'

const Schema = yup.object().shape({
  name: yup.string().required("Необходимо ввести имя"),
  phone: yup.string().min(18).required("Необходимо ввести телефон")
})

interface ICallBack {
  open: boolean
  setOpen: any
}

const StyledLoadingButton = styled(LoadingButton, {
  name: "StyledLoadingButton",
  slot: "Wrapper"
})({
  "color": "rgba(0, 0, 0, 1)",
  "border-radius": "16px",
  "background": "rgb(64, 201, 255)",
  "height": "55px",
  "transition": "0.5s ease-in-out, box-shadow 0.5s ease-in-out",
  "&:hover": {"background": "rgb(64, 201, 255)",   "transform": "scale(1.05)" }
})

const CallBack: FC<ICallBack> = ({open=false, setOpen}) => {

  const [ send, setSend ] = useState<boolean>(false)
  const [ loading, setLoading ] = useState<boolean>(false)
  
  useEffect(() => {
    AOS.init()
  }, [])

  const { handleSubmit, control, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      name: '',
      phone: ''
    }
  })

  useEffect(() => {
    if(send){
      setTimeout(()=>{
        setOpen(false)
      }, 2000)
    }
  }, [send, setOpen])
  
  const onSubmit = async (data:any) => {
    setLoading(true)
    axios({
      method: 'POST',
      url: `/call-back`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        name: getValues('name'),
        phone: getValues('phone')
      }
    })
    .then(() => {
      ym('reachGoal','send_top_form')
      setSend(true)
      setLoading(false)
      console.log(data)
    })
    .catch((error:any) => {
      setLoading(false)
      console.log(error)
    })
  }
  
  const close = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setOpen(false)
  }
  
  if(send){
    return (
      <Modal
      disableAutoFocus
      disableEnforceFocus
      open={open}
      onClose={close}
      sx={{'justify-content': 'center','display': 'flex', 'align-items': 'center'}}
      >
        <Fade in={open}>
          <div className={styles.content}>
            <div className={styles.first}/>
            <div className={styles.two}/>
            <div className={`${styles.header} ${styles.successfully}`}>
              <Stack direction="row" spacing={1} onClick={close}>
                <IconButton aria-label="close" size="large" style={{position: 'absolute', top: 5, right: 5, color: 'rgba(0, 0, 0, 1)', fontWeight: 800, transform: 'scale(1.35)'}}>
                  <CloseIcon/>
                </IconButton>
              </Stack>
              <h3>
                Мы свяжемся с вами в ближайшее время
              </h3>
            </div>
          </div>
        </Fade>
      </Modal>
    )
  }

  
  return ( 
    <Modal
    disableAutoFocus
    disableEnforceFocus
    open={open}
    onClose={close}
    sx={{'justify-content': 'center','display': 'flex', 'align-items': 'center'}}
    >
      <Fade in={open}>
        <div  className={styles.content}>
          <div className={styles.first}/>
          <div className={styles.two}/>
          <Stack direction="row" spacing={1} onClick={close}>
            <IconButton aria-label="close" size="large" style={{position: 'absolute', top: 5, right: 5, color: 'rgba(0, 0, 0, 1)', fontWeight: 800, transform: 'scale(1.35)'}}>
              <CloseIcon/>
            </IconButton>
          </Stack>
          <div className={styles.header}>
            <h1>Мы свяжемся с вами в ближайшее время</h1>
          </div>
          <div className={styles.form}>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '97.5%' },
              }}
              noValidate
              autoComplete="off"
            >
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { isTouched, isDirty, error },
                }) => (
                  <TextField
                    placeholder="Имя"
                    value={value}
                    onBlur={onBlur}
                    inputRef={ref}
                    onChange={onChange}
                  />
                )}
                name="name"
                control={control}
                rules={{ required: true }}
              />
              {errors?.name && <p className={styles.error}>{errors.name.message}</p>}

              <Controller
                name="phone"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <MuiPhoneNumber
                  variant="outlined"
                  defaultCountry={'ru'}
                  regions={'europe'}
                  onChange={onChange}
                  value={value}
                  />
                )}
              />
              
              {errors?.phone && errors.phone.type === "required" && <p className={styles.error}>{errors.phone.message}</p>}
              {errors?.phone && errors.phone.type === "min" && <p className={styles.warning}>телефон должен быть не менее 11 символов</p> }

              <StyledLoadingButton loading={loading} variant="outlined" onClick={handleSubmit(onSubmit)}>Отправить</StyledLoadingButton>
              
            </Box>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}

export default CallBack
