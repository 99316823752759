import { FC, useEffect, useState } from 'react'

import styles from './styles.module.scss'

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const ToTop: FC = () => {

  const [scroll, setScroll] = useState<number>(0)

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  })
  
  const onScroll = (e: Event) => {
    const window = e.currentTarget as Window
    let currentPosition = window.scrollY
    setScroll(currentPosition)
  }
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <div className={styles.toTop} style={{bottom: scroll > 100 ? 20: -100}} onClick={scrollToTop}>
      <KeyboardArrowUpIcon fontSize='large'/>
    </div>
  )
}

export default ToTop
