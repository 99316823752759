
import { FC, useState } from 'react'

import styles from './styles.module.scss'

import CallBack from '@components/CallBack'

const WhydoYouNeed: FC<any> = (data) => {

  const [callback, setCallback] = useState<boolean>(false)

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div id='Intro' className={styles.section}>
          <div className={styles.first}/>
          <div className={styles.two}/>
          <div className={styles.three}/>
          <div className={styles.four}/>
          <div className={styles.five}/>
          <div className={styles.six}/>
          <div className={styles.seven}/>
          <CallBack open={callback} setOpen={setCallback}/>
          <h1>
            ВИБРОДИНАМИК ДЛЯ СОСЕДЕЙ
          </h1>
          <h2>
            Эффективное решение для непонятливых
          </h2>
          <div className={`${styles.buttons}`}>
            <a href='#Call' className={`${styles.button}`} onClick={()=>{
                setCallback(!callback)
              }}>
              Заказать комплект антисосед
            </a>
          </div>
          <div className={styles.image}/>
        </div>
      </div>
    </div>
  )
}

export default WhydoYouNeed
