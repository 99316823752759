import { FC } from 'react'

import scrollToElement from 'scroll-to-element'

import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import { ThemeProvider, createTheme } from '@mui/material/styles'

import useMediaQuery from '@mui/material/useMediaQuery'

import styles from './styles.module.scss'

const theme = createTheme({
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "rgb(52, 52, 52)",

        }
      }
    },
    },
  transitions: {
    duration: {
        enteringScreen: 1000,
        leavingScreen: 350,
    }
  }
})

interface IMiniBt {
  open: boolean
  setOpen: any
}

const Menu: FC<IMiniBt> = ({open=false, setOpen}) => {

  const mobile = useMediaQuery('(min-width:1200px)')

  const scrollTo = (to:string, offset: number):void => {
    
    scrollToElement(to, {
        offset: offset,
        duration: 1800
    })

    setOpen(false)
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <SwipeableDrawer
          anchor={'right'}
          open={open}
          onClose={()=>{setOpen(false)}}
          onOpen={()=>{setOpen(true)}}
          minFlingVelocity={50}
          sx={{ 
            "&" : {
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
            backdropFilter: mobile ? "blur(5px)":"unset",
            width: "100%",
            }
          }}
        >
          <div className={styles.content}>
            <div className={styles.logo}>
              <div onClick={()=>{scrollTo('#Intro', 0)}}>
                Антисосед.рф
              </div>
            </div>
            <div className={styles.menu}>
              <div onClick={()=>{scrollTo('#PhotoGallery', 0)}}>
                  Комплект
              </div>
              <div onClick={()=>{scrollTo('#Suitable', 0)}}>
                  Характеристики
              </div>
              <div onClick={()=>{scrollTo('#WhydoYouNeed', -50)}}>
                    Отзывы
              </div>
              <div onClick={()=>{scrollTo('#WhyAreWe', 0)}}>
                    Доставка и оплата
              </div>
              <div onClick={()=>{scrollTo('#Faq', -50)}}>
                    Вопросы - ответы
              </div>     
              <div onClick={()=>{scrollTo('#Footer', 0)}}>
                    Контакты
              </div>
            </div>
            <div className={styles.oth}>
              <div className={styles.phone}>
                <a href='tel:+7 950 291 33 51'>+7 950 291 33 51</a>
                <a href='email:info@Антисосед.рф'>info@Антисосед.рф</a>
              </div>
              <div className={styles.address}>
                г. Владивосток, ул. Сельская, 6
              </div>
            </div>
          </div>
        </SwipeableDrawer>
      </ThemeProvider>
    </>
  )
}

export default Menu
