
import './App.css'

import WebFont from 'webfontloader';

import { useEffect, useState } from 'react'

import { YMInitializer } from 'react-yandex-metrika';

import Header from '@components/Header'
import Intro from '@components/Intro'

import WhyAreWe from '@components/WhyAreWe'
import WhydoYouNeed from '@components/WhydoYouNeed'
import Save from '@components/Save'
import Suitable from '@components/Suitable'

import Faq from '@components/Faq'
import Footer from '@components/Footer'

import PhotoGallery from '@components/PhotoGallery'
import Timer from '@components/Timer'

import CallBack from '@components/CallBack'



import Training from '@components/Training'
import ToTop from '@components/ToTop'


import { createTheme, ThemeProvider } from '@mui/material/styles'

import grey from '@mui/material/colors/grey'

const theme = createTheme({
  
  palette: {
    primary: grey,
  },
  components: {
  
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '25px',
          borderColor: 'none',
          border: 0,
          '& .MuiOutlinedInput-root': {
            borderRadius: '25px',
            height: 50,
            border: '0px solid #909090',

            ':hover': {
              border: '0px solid #fd0000 !important',
              boxShadow: '-1px 1px 4px 4px rgba(219, 219, 219, 0.25);',

              borderRadius: '25px',
              borderColor: 'none',
            },
            ':focus-within': { border: '0px solid #fd0000 !important' }
          },
          '& .MuiOutlinedInput-root.Mui-disabled': {
            ':hover': {
              border: '0px solid #909090 !important',
              boxShadow: 'none'
            }
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          }
        },
      }
    },
    
    MuiInputBase: {
        styleOverrides: {
          root: {
            color: '#7A7A7A',
            background: '#F3F3F3',
            borderWidth: '26px',

            borderRadius: '25px',
            borderColor: 'none',
            border: 0,
            ':hover': {
              borderRadius: '25px',
              borderColor: 'none',
              border: 0,
            },

            '& .MuiOutlinedInput-root': {
              borderRadius: '7px',
              height: 50,
              border: '1px solid #909090',
              ':hover': {
                border: '0px solid #fd0000 !important',
                boxShadow: '-1px 1px 4px 4px #FFEAEA'
              },
              ':focus-within': { border: '0px solid #fd0000 !important' }
            },
            '& .MuiOutlinedInput-root.Mui-disabled': {
              ':hover': {
                border: '0px solid #909090 !important',
                boxShadow: 'none'
              }
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none'
            }
          },
        }
    }
}
})



const App = () => {

  const [callback, setCallback] = useState<boolean>(false)

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Inter', 'Chilanka']
      }
    })
   }, [])

  return  <>
            <ThemeProvider theme={theme}>
              <CallBack open={callback} setOpen={setCallback}/>
              <Header/>
              <Intro/>
              <Suitable/>
              <WhydoYouNeed/>
              
              <Faq/>
              <Save/>
              <WhyAreWe/>
              <Timer/>
              <PhotoGallery/>
              <Footer/>
             
              <Training/>
              <ToTop/>

              
              <YMInitializer accounts={[96400363]} options={{webvisor: true}} version="2"  />

            </ThemeProvider>
          </>
  
}

export default App
