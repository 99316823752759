
import { FC, useEffect } from 'react'

import AOS from 'aos'
import 'aos/dist/aos.css'

import styles from './styles.module.scss'

import variants from './variants.json'

const WhyAreWe: FC<any> = () => {

  useEffect(() => {
    AOS.init()
  }, [])
  
  return (
    <div className={styles.container}>
      <div id='WhyAreWe' className={styles.content}>

        <h1 data-aos="zoom-in">Доставка и оплата</h1>

        <div className={styles.section}>

          {variants?.map((item:any, index:number) => (
            <div data-aos="zoom-in" data-aos-duration={500*index} key={item.title} className={styles.item}>
              <div className={styles.first}/>
              <div className={styles.two}/>
              <div className={styles.three}/>
              <div className={styles.title}>
                {item.title}
              </div>
              <div className={styles.content}>
                {item.content}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default WhyAreWe
