
import { FC, useEffect } from 'react'

import AOS from 'aos'
import 'aos/dist/aos.css'

import styles from './styles.module.scss'

const Save: FC<any> = (data) => {

  useEffect(() => {
    AOS.init()
  }, [])
  
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div id='Save' className={styles.section}>

          <div className={styles.first}/>
          <div className={styles.two}/>
          <div className={styles.three}/>
          <div className={styles.four}/>
          <div className={styles.five}/>
          <div className={styles.six}/>
          <div className={styles.seven}/>

          <h1 data-aos="zoom-in">
            Сохрани свои нервы
          </h1>

          <div className={styles.content}>

            <div className={styles.flex}>

              <div data-aos="fade-right" className={styles.left}>
                  <img
                  src='/images/Save/Mask group.png'
                  alt="Шумные соседи могут вызвать проблемы с психикой"
                  />
                <div>
                  Шумные соседи могут вызвать проблемы с психикой
                </div>
              </div>

              <div data-aos="zoom-in" className={styles.center}>

              </div>

              <div data-aos="fade-left" className={styles.right}>
                  <img
                  src='/images/Save/Mask group2.png'
                  alt="Когда купил комплект АнтиСосед"
                  />
                <div>
                Когда купил комплект АнтиСосед
                </div>
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>
  )
}

export default Save
