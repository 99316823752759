
import { FC, useEffect } from 'react'

import AOS from 'aos'
import 'aos/dist/aos.css'

import useMediaQuery from '@mui/material/useMediaQuery'

import styled, { css, keyframes } from "styled-components"

import styles from './styles.module.scss'

import variants from './variants.json'

const setAnimation = ({ speed = 4, direction = "top", widthOrHeight=85, length=1 }) => {
  
  const fixed = widthOrHeight*length

  let animation = keyframes`
    0% {
      ${direction}: ${direction ==='bottom' ? 0: 0}px;
    }
    100% { 
      ${direction}: ${direction ==='bottom' ? -fixed: -fixed}px;
    }
  `;
    
  if(direction==='left'||direction==='right'){
    animation = keyframes`
    0% {
      ${direction}: ${direction ==='left' ? 0: 0}px;

    }
    100% { 
      ${direction}: ${direction ==='left' ? -fixed: -fixed}px;
    }
  `;
  }

  const  display = direction==='left'||direction==='right' ? 'flex':'block'

  return css`
    position: absolute;
    display: ${display};
    padding: 0;
    margin: 0;
    animation: ${animation} ${speed}s linear infinite;
  `;
}

const Animation = styled.div`
  ${setAnimation}
`
const WhydoYouNeed: FC<any> = (data) => {

  useEffect(() => {
    AOS.init()
  }, [])
  
  const matchesPc = useMediaQuery('(min-width:1200px)')

  const widthOrHeight = 360
  const margin = 10

  const width = 360
  const height = 280


  // for mobile
  const widthMobile = 357
  const heightMobile = 252

  const speed = 40

  return ( 
    <div className={styles.container}>
      <div className={styles.content}>
        <div id='WhydoYouNeed' className={styles.section}>

          <div className={styles.row}>
            <div data-aos="zoom-in" className={styles.title}>
              Отзывы на комплект антисосед
            </div>
            <div className={styles.description}>

            </div>
          </div>

          <div className={styles.row}>
            { matchesPc ?
            <>
              <div className={styles.animate} style={{
                height: 650,
                width: widthOrHeight+(margin*2),
              }}>
                <Animation speed={speed} direction="top" widthOrHeight={height+(margin*1)} length={variants.length}>
                  {variants.map((item:any, index:number) => (
                    <div key={index} className={styles.item} style={{
                      height: height,
                      width: width,
                      margin: margin,
                    }}>
                      <div className={styles.image}/>
                      <div className={styles.title}>
                        {item.title}
                      </div>
                      <div className={styles.description}>
                        {item.description}
                      </div>
                    </div>
                  ))}
                  {variants.map((item:any, index:number) => (
                    <div key={index} className={styles.item} style={{
                      height: height,
                      width: width,
                      margin: margin,
                    }}>
                      <div className={styles.image}/>
                      <div className={styles.title}>
                        {item.title}
                      </div>
                      <div className={styles.description}>
                        {item.description}
                      </div>
                    </div>
                  ))}
                </Animation>
              </div>

              <div className={styles.animate} style={{
                height: 650,
                width: widthOrHeight+(margin*2),
              }}>
                <Animation speed={speed} direction="bottom" widthOrHeight={height+(margin)} length={variants.length}>
                  {variants.reverse().map((item:any, index:number) => (
                    <div key={index} className={styles.item} style={{
                      height: height,
                      width: width,
                      margin: margin,
                    }}>
                      <div className={styles.image}/>
                      <div className={styles.title}>
                        {item.title}
                      </div>
                      <div className={styles.description}>
                        {item.description}
                      </div>
                    </div>
                  ))}
                  {variants.map((item:any, index:number) => (
                    <div key={index} className={styles.item} style={{
                      height: height,
                      width: width,
                      margin: margin,
                    }}>
                      <div className={styles.image}/>
                      <div className={styles.title}>
                        {item.title}
                      </div>
                      <div className={styles.description}>
                        {item.description}
                      </div>
                    </div>
                  ))}
                </Animation>
              </div>
            </>
            :
            <>
              <div className={styles.animate} style={{
                height: heightMobile+(margin*2),
                width: '100vw',
              }}>
                <Animation speed={speed*2} direction="left" widthOrHeight={widthMobile+(margin*2)} length={variants.length}>
                  {variants.map((item:any, index:number) => (
                    <div key={index} className={styles.item} style={{
                      height: heightMobile,
                      width: widthMobile,
                      margin: margin,
                    }}>
                      <div className={styles.image}/>
                      <div className={styles.title}>
                        {item.title}
                      </div>
                      <div className={styles.description}>
                        {item.description}
                      </div>
                    </div>
                  ))}
                  {variants.map((item:any, index:number) => (
                    <div key={index} className={styles.item} style={{
                      height: heightMobile,
                      width: widthMobile,
                      margin: margin,
                    }}>
                      <div className={styles.image}/>
                      <div className={styles.title}>
                        {item.title}
                      </div>
                      <div className={styles.description}>
                        {item.description}
                      </div>
                    </div>
                  ))}
                </Animation>
              </div>

            </>
            }

            <div className={styles.first1}/>
            <div className={styles.two1}/>
            <div className={styles.three1}/>
            
            <div className={styles.first}/>
            <div className={styles.two}/>
            <div className={styles.three}/>
            <div className={styles.four}/>
            <div className={styles.five}/>
            <div className={styles.six}/>
            <div className={styles.seven}/>
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhydoYouNeed
