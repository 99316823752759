
import { FC, useCallback, useEffect, useState } from 'react'

import AOS from 'aos'
import 'aos/dist/aos.css'

import msToHMS from '@utils/msToHMS'

import CallBack from '@components/CallBack'

import styles from './styles.module.scss'

const openPageTimestamp = Date.now()

let randomNumber = Math.floor(Math.random() * 6) + 5

const Timer: FC<any> = () => {

  useEffect(() => {
    AOS.init()
  }, [])
  
  const [timer, setTimer] = useState('00:00:00')
  const [losses, setLosses] = useState(0)

  const [callback, setCallback] = useState<boolean>(false)

  const tickTimer = useCallback(() => {
    setTimer(msToHMS(openPageTimestamp-Date.now()))
  }, [setTimer])

  useEffect(() => {
    const interval = setInterval(() => {
      setLosses(losses + 1)
      tickTimer()
    }, 1000 * randomNumber);
    return () => clearInterval(interval)
  }, [losses, setLosses, tickTimer  ])

  useEffect(() => {
    const timerID = setInterval(() => tickTimer(), 1000)
    return () => clearInterval(timerID)
  }, [tickTimer])
  
  let time: string = timer;
  let timeArray: string[] = time.split(":")

  return (
    <div className={styles.container}>
      <div data-aos="zoom-in" data-aos-duration="2000" className={styles.content}>
        <div id='Timer' className={styles.section}>
          <div className={`${styles.content}`}>
          <CallBack open={callback} setOpen={setCallback}/>

            <div className={styles.title}>
              Вы провели на сайте
            </div>
            
            <div className={styles.timercontainer}>

            <div className={styles.timer}>
              
              <div className={styles.time}>
              {timeArray[0]}
              </div>
              <div className={styles.time}>
              :
              </div>
              <div className={styles.time}>
              {timeArray[1]}
              </div>
              <div className={styles.time}>
              :
              </div>
              <div className={styles.time}>
              {timeArray[2]}
              </div>

              <div className={styles.times}>
                часы
              </div>
              <div></div>
              <div className={styles.times}>
                минуты
              </div>
              <div></div>
              <div className={styles.times}>
                секунды
              </div>

              </div>
              </div>

            <div className={styles.losses}>
              В среднем это равняется <b>{losses} ударам</b> головой об стену соседа когда работает вибродинамик.
            </div>
            <div className={styles.description}>

            </div>
            <button data-aos="zoom-in" data-aos-duration="1500" className={styles.button} onClick={()=>{
                setCallback(!callback)
              }}>
              Заказать комплект антисосед
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Timer
